import React, { Component,Fragment } from 'react'
import { ShoppingBag, Download, AlertCircle } from 'react-feather';


export class Notification extends Component {
    render() {
        return (
            <Fragment>

                        <ul className="notification-dropdown onhover-show-div p-0">
                            <li>Notification <span className="badge badge-pill badge-primary pull-right">0</span></li>
                            <li>
                                <div className="media">
                                    <div className="media-body">
                                        <h6 className="mt-0 txt-danger"><span><AlertCircle /></span>Coming Soon</h6>
                                        <p className="mb-0">No new notifications.</p>
                                    </div>
                                </div>
                            </li>
                            <li className="txt-dark"><a href="#">All notification</a></li>
                        </ul>
            </Fragment>
        )
    }
}

export default Notification
