import React, {Component, Fragment} from 'react';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import {User} from 'react-feather';
import {config} from '../../config'
import {withRouter} from 'react-router-dom';
import {login} from "../../services/api";
import {setToken} from "../../utils/utility";

const jwtToken = config.jwtAuthToken;

export class LoginTabset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeShow: true,
      userName: '',
      password: '',
      isAuthenticating: false,
      errors: {
        password: false,
        userName: false,
        other: '',
      },
      startDate: new Date()
    };

    this.getJwtToken = this.getJwtToken.bind(this);
    this.handleChange = this.handleChange.bind(this)
  }

  getJwtToken = (response) => (
    response.headers[jwtToken]
  );

  clickActive = (event) => {
    document.querySelector(".nav-link").classList.remove('show');
    event.target.classList.add('show');
  };

  handleChange(date) {
    this.setState({
      startDate: date
    });
  }

  loginClick() {
    const {
      userName, password, isAuthenticating,
    } = this.state;

    if (isAuthenticating) {
      return;
    }
    if (userName === '') {
      this.setState({
        errors: {
          password: false,
          userName: true,
        },
      });
      return;
    }
    if (password === '') {
      this.setState({
        errors: {
          password: true,
          userName: false,
        },
      });
      return;
    }

    this.setState({
      isAuthenticating: true,
    });

    login(userName, password)
      .then((res) => {
        const token = this.getJwtToken(res);
        setToken(token);
        window.location.href = `${process.env.PUBLIC_URL}/products/product-list`;
      })
      .catch((error) => {
        const { response: { status, data } } = error;
        if (status === 401 || status === 403) {
          this.setState({
            errors: {
              userName: true,
              password: true,
            },
            isAuthenticating: false,
          });
        }
      })
      .finally(() => {
        this.setState({
          isAuthenticating: false,
        });
      });
  }

  setValue = (name, event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render() {
    const {userName, password, errors} = this.state;
    return (
      <div>
        <Fragment>
          <Tabs>
            <img height="60px" width="60px" style={{marginLeft: '40%'}}
                 src="https://i.pinimg.com/280x280_RS/8a/88/03/8a880303316004704633b48e70d12e70.jpg"/>
            <TabList className="nav nav-tabs tab-coupon">
              <Tab className="nav-link" onClick={(e) => this.clickActive(e)}><User/>Login</Tab>
            </TabList>

            <TabPanel>
              <div className="form-horizontal auth-form">
                <div>
                  {errors.other}
                </div>
                <div className="form-group">
                  <input required="" name="login[username]" value={userName} className="form-control"
                         style={errors.userName ? {border: '1px solid red'}: {border: '1px solid #eaeaea'}}
                         onChange={(e) => this.setValue("userName", e)}
                         placeholder="Username"
                         id="exampleInputEmail1"/>
                </div>
                <div className="form-group">
                  <input required="" name="login[password]" type="password" value={password}
                         style={errors.password ? {border: '1px solid red'}: {border: '1px solid #eaeaea'}}
                         onChange={(e) => this.setValue("password", e)}
                         className="form-control"
                         placeholder="Password"/>
                </div>
                <div className="form-terms">
                  <div className="custom-control custom-checkbox mr-sm-2">
                    <input type="checkbox" className="custom-control-input" id="customControlAutosizing"/>
                    <label className="d-block">
                      <input className="checkbox_animated" id="chk-ani2" type="checkbox"/>
                      Remember Me <span className="pull-right">
                      <button onClick={() => alert("El chsxmes dra vra")}
                              style={{
                                background: 'none',
                                color: '#ff8084'
                              }}
                              className="btn btn-default forgot-pass p-0">lost your password</button>
                    </span>
                    </label>
                  </div>
                </div>
                <div className="form-button">
                  <button className="btn btn-primary" onClick={() => this.loginClick()}>Login</button>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </Fragment>
      </div>
    )
  }
}

export default withRouter(LoginTabset)

