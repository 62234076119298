import React, {Component, Fragment} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import {AvField, AvForm} from 'availity-reactstrap-validation';

import Switch from "react-switch";

import {getCountryById, saveCountry} from '../../../services/api'

import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {Link} from "react-router-dom";
import * as qs from "query-string";


export class CountryDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: null,
            isActive: false,
            isDeleted: false,
            name: '',
            shippingDetails: [
                {
                    shippingMethod: {
                        name: "Hay Post EMS",
                        shippingMethod: "HAY_POST_EMS",
                        shippingPeriod: "7 - 15 business days"
                    },
                    isActive: false,
                    priceMap: {
                        PRICE_0_500: {
                            ARMENIA: 0,
                            RUSSIA: 0,
                            EUROPE: 0,
                            OTHER: 0,
                        },
                        PRICE_501_1000: {
                            ARMENIA: 0,
                            RUSSIA: 0,
                            EUROPE: 0,
                            OTHER: 0,
                        },
                        PRICE_FOR_EACH_1000: {
                            ARMENIA: 0,
                            RUSSIA: 0,
                            EUROPE: 0,
                            OTHER: 0,
                        },
                    }
                },
                {
                    shippingMethod: {
                        name: "Hay Post",
                        shippingMethod: "HAY_POST",
                        shippingPeriod: "14 - 30 business days"
                    },
                    isActive: false,
                    priceMap: {
                        PRICE_0_100: {
                            ARMENIA: 0,
                            RUSSIA: 0,
                            EUROPE: 0,
                            OTHER: 0,
                        },
                        PRICE_101_250: {
                            ARMENIA: 0,
                            RUSSIA: 0,
                            EUROPE: 0,
                            OTHER: 0,
                        },
                        PRICE_251_500: {
                            ARMENIA: 0,
                            RUSSIA: 0,
                            EUROPE: 0,
                            OTHER: 0,
                        },
                        PRICE_501_750: {
                            ARMENIA: 0,
                            RUSSIA: 0,
                            EUROPE: 0,
                            OTHER: 0,
                        },
                        PRICE_751_1000: {
                            ARMENIA: 0,
                            RUSSIA: 0,
                            EUROPE: 0,
                            OTHER: 0,
                        },
                        PRICE_1001_1500: {
                            ARMENIA: 0,
                            RUSSIA: 0,
                            EUROPE: 0,
                            OTHER: 0,
                        },
                        PRICE_0_1000: {
                            ARMENIA: 0,
                            RUSSIA: 0,
                            EUROPE: 0,
                            OTHER: 0,
                        },
                        PRICE_FOR_EACH_1000: {
                            ARMENIA: 0,
                            RUSSIA: 0,
                            EUROPE: 0,
                            OTHER: 0,
                        },
                    }
                }
            ],
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {location: {search}} = nextProps;
        const queries = qs.parse(search, {ignoreQueryPrefix: true});
        const countryId = queries.countryId;
        if (countryId) {
            getCountryById(countryId)
                .then(country => {
                        if (country) {
                            this.setState({
                                id: country.id,
                                name: country.name,
                                isActive: country.isActive,
                                isDeleted: country.isDeleted,
                                shippingDetails: country.shippingDetails
                            })
                        } else {
                            this.setState({});
                        }
                    }
                )
        } else {
            this.setState({
                id: null,
                isActive: false,
                isDeleted: false,
                name: '',
                shippingDetails: [
                    {
                        shippingMethod: {
                            name: "Hay Post EMS",
                            shippingMethod: "HAY_POST_EMS"
                        },
                        isActive: false,
                        shippingPeriod: "7 - 15 business days",
                        priceMap: {
                            PRICE_0_500: {
                                EUROPE: 0,
                                ARMENIA: 0,
                                OTHER: 0,
                            },
                            PRICE_501_1000: {
                                EUROPE: 0,
                                ARMENIA: 0,
                                OTHER: 0,
                            },
                            PRICE_FOR_EACH_1000: {
                                EUROPE: 0,
                                ARMENIA: 0,
                                OTHER: 0,
                            },
                        }
                    },
                    {
                        shippingMethod: {
                            name: "Hay Post",
                            shippingMethod: "HAY_POST"
                        },
                        isActive: false,
                        shippingPeriod: "14 - 30 business days",
                        priceMap: {
                            PRICE_0_100: {
                                EUROPE: 0,
                                ARMENIA: 0,
                                OTHER: 0,
                            },
                            PRICE_101_250: {
                                EUROPE: 0,
                                ARMENIA: 0,
                                OTHER: 0,
                            },
                            PRICE_251_500: {
                                EUROPE: 0,
                                ARMENIA: 0,
                                OTHER: 0,
                            },
                            PRICE_501_1000: {
                                EUROPE: 0,
                                ARMENIA: 0,
                                OTHER: 0,
                            },
                            PRICE_1001_1500: {
                                EUROPE: 0,
                                ARMENIA: 0,
                                OTHER: 0,
                            },
                            PRICE_FOR_EACH_1000: {
                                EUROPE: 0,
                                ARMENIA: 0,
                                OTHER: 0,
                            },
                        }
                    }
                ],
            });
        }
    }

    componentDidMount() {
        const {location: {search}} = this.props;
        const queries = qs.parse(search, {ignoreQueryPrefix: true});
        const countryId = queries.countryId;

        if (countryId) {
            getCountryById(countryId)
                .then(country => {
                        if (country) {
                            this.setState({
                                id: country.id,
                                name: country.name,
                                isActive: country.isActive,
                                isDeleted: country.isDeleted,
                                shippingDetails: country.shippingDetails

                            });
                        }
                    }
                )
        } else {
            this.setState({
                id: null,
                isActive: false,
                isDeleted: false,
                name: '',
                shippingDetails: [
                    {
                        shippingMethod: {
                            name: "Hay Post EMS",
                            shippingMethod: "HAY_POST_EMS"
                        },
                        shippingPeriod: "7 - 15 business days",
                        isActive: false,
                        priceMap: {
                            PRICE_0_500: {
                                ARMENIA: 0,
                                RUSSIA: 0,
                                EUROPE: 0,
                                OTHER: 0,
                            },
                            PRICE_501_1000: {
                                ARMENIA: 0,
                                RUSSIA: 0,
                                EUROPE: 0,
                                OTHER: 0,
                            },
                            PRICE_FOR_EACH_1000: {
                                ARMENIA: 0,
                                RUSSIA: 0,
                                EUROPE: 0,
                                OTHER: 0,
                            },
                        }
                    },
                    {
                        shippingMethod: {
                            name: "Hay Post",
                            shippingMethod: "HAY_POST"
                        },
                        isActive: false,
                        shippingPeriod: "14 - 30 business days",
                        priceMap: {
                            PRICE_0_100: {
                                ARMENIA: 0,
                                RUSSIA: 0,
                                EUROPE: 0,
                                OTHER: 0,
                            },
                            PRICE_101_250: {
                                ARMENIA: 0,
                                RUSSIA: 0,
                                EUROPE: 0,
                                OTHER: 0,
                            },
                            PRICE_251_500: {
                                ARMENIA: 0,
                                RUSSIA: 0,
                                EUROPE: 0,
                                OTHER: 0,
                            },
                            PRICE_501_750: {
                                ARMENIA: 0,
                                RUSSIA: 0,
                                EUROPE: 0,
                                OTHER: 0,
                            },
                            PRICE_751_1000: {
                                ARMENIA: 0,
                                RUSSIA: 0,
                                EUROPE: 0,
                                OTHER: 0,
                            },
                            PRICE_1001_1500: {
                                ARMENIA: 0,
                                RUSSIA: 0,
                                EUROPE: 0,
                                OTHER: 0,
                            },
                            PRICE_0_1000: {
                                ARMENIA: 0,
                                RUSSIA: 0,
                                EUROPE: 0,
                                OTHER: 0,
                            },
                            PRICE_FOR_EACH_1000: {
                                ARMENIA: 0,
                                RUSSIA: 0,
                                EUROPE: 0,
                                OTHER: 0,
                            },
                        }
                    }
                ],
            });
        }
    }

    changeField = (event, name) => {
        this.setState({
            [name]: event.target.value
        });
    };

    changeIsActive = () => {
        this.setState({
            isActive: !this.state.isActive
        });
    };

    changeIsDeleted = () => {
        this.setState({
            isDeleted: !this.state.isDeleted
        });
    };

    changeShippingMethodIsActive = (index) => {
        const {shippingDetails} = this.state;
        shippingDetails[index].isActive = !shippingDetails[index].isActive;
        this.setState({
            shippingDetails
        });
    };

    handleSaveClick() {
        const country = this.state;
        if (!country.name) {
            toast.error("Name is empty!")
            return;
        }
        saveCountry(country)
            .then(response => {
                toast.success("Successfully Saved.");
                this.setState({
                    id: response.id
                });
                window.location.href = "/countries?countryId=" + response.id
            })
            .catch(err => {
                toast.error("Can't Save this country.");
            });
    }

    handleChange(event, index, shippingDetailUpdated, key, location) {
        const {shippingDetails} = this.state;
        shippingDetails[index].priceMap[key][location] = event.target.value;

        this.setState({
            shippingDetails
        });
    }

    mapLocation(location) {
        if (location === "EUROPE") {
            return "Europe | EURO | €";
        } else if (location === "ARMENIA") {
            return "Armenia | AMD | ֏";
        } else if (location === "OTHER") {
            return "Other | USD | $";
        }  else if (location === "RUSSIA") {
            return "Russia | RUB | ₽";
        } else {
            return location;
        }
    }

    mapPriceType(type) {
        if (type === "PRICE_0_500") {
            return "Price 0 - 500";
        } else if (type === "PRICE_501_1000") {
            return "Price 501 - 1000";
        } else if (type === "PRICE_0_100") {
            return "Price 0 - 100";
        } else if (type === "PRICE_101_250") {
            return "Price 101 - 250";
        } else if (type === "PRICE_251_500") {
            return "Price 251 - 500";
        } else if (type === "PRICE_501_750") {
            return "Price 501 - 750";
        } else if (type === "PRICE_751_1000") {
            return "Price 751 - 1000";
        } else if (type === "PRICE_1001_1500") {
            return "Price 1001 - 1500";
        } else if (type === "PRICE_0_1000") {
            return "Price 0 - 1000";
        } else if (type === "PRICE_FOR_EACH_1000") {
            return "Price for each 1000";
        } else {
            return type;
        }
    }

    render() {

        return (
            <Fragment>
                <Breadcrumb title="Add / Edit Country" parent="Physical"/>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Add / Edit Country</h5>
                                </div>
                                <div className="card-body">
                                    <div className="row product-adding">
                                        <div className="col-xl-7">
                                            <AvForm className="needs-validation add-product-form">
                                                <div className="form form-label-center">
                                                    <div className="form-group mb-3 row">
                                                        <label className="col-xl-3 col-sm-4 mb-0">Country Name :</label>
                                                        <div className="col-xl-8 col-sm-7">
                                                            <AvField className="form-control" name="product_name"
                                                                     type="text" required value={this.state.name}
                                                                     onChange={(event) => this.changeField(event, 'name')}/>
                                                        </div>
                                                        <div className="valid-feedback">Looks good!</div>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-3 row">
                                                    <label className="col-xl-3 col-sm-4 mb-0">Active:</label>
                                                    <div className="col-xl-8 col-sm-7">
                                                        <Switch onChange={this.changeIsActive}
                                                                checked={this.state.isActive}/>
                                                    </div>
                                                    <div className="valid-feedback">Looks good!</div>
                                                </div>
                                                <div className="form-group mb-3 row">
                                                    <label className="col-xl-3 col-sm-4 mb-0">Deleted:</label>
                                                    <div className="col-xl-8 col-sm-7">
                                                        <Switch onChange={this.changeIsDeleted}
                                                                checked={this.state.isDeleted}/>
                                                    </div>
                                                    <div className="valid-feedback">Looks good!</div>
                                                </div>
                                                {/*start price part*/}
                                                {this.state.shippingDetails.map((shippingDetail, i) => {
                                                    return (
                                                        <div style={{
                                                            padding: '5%',
                                                            marginBottom: '5%',
                                                            border: '1px solid black'
                                                        }}>
                                                            <h3 style={{
                                                                color: '#650000',
                                                                textAlign: 'center'
                                                            }}>-------------- {shippingDetail.shippingMethod.name}
                                                                -------------</h3>
                                                            <h3 style={{
                                                                color: '#650000',
                                                                textAlign: 'center'
                                                            }}> {shippingDetail.shippingPeriod}</h3>
                                                            <div className="form-group mb-3 row">
                                                                <label
                                                                    className="col-xl-3 col-sm-4 mb-0">Active:</label>
                                                                <div className="col-xl-8 col-sm-7">
                                                                    <Switch
                                                                        onChange={() => this.changeShippingMethodIsActive(i)}
                                                                        checked={shippingDetail.isActive}/>
                                                                </div>
                                                                <div className="valid-feedback">Looks good!</div>
                                                            </div>


                                                            <div>
                                                                {
                                                                    Object.keys(shippingDetail.priceMap).map(key => {
                                                                        return (
                                                                            <div style={{
                                                                                border: '1px solid black',
                                                                                margin: '2%',
                                                                                padding: '2%'
                                                                            }}>
                                                                                <h3 style={{color: 'black'}}>{this.mapPriceType(key)}</h3>
                                                                                {
                                                                                    Object.keys(shippingDetail.priceMap[key]).map(location => {
                                                                                        return (
                                                                                            <div
                                                                                                className="form-group row">
                                                                                                <label
                                                                                                    className="col-xl-3 col-sm-4 mb-0">{this.mapLocation(location)}</label>
                                                                                                <fieldset
                                                                                                    className="qty-box ml-0">
                                                                                                    <div
                                                                                                        className="input-group bootstrap-touchspin">
                                                                                                        <input
                                                                                                            className="touchspin form-control"
                                                                                                            type="number"
                                                                                                            value={shippingDetail.priceMap[key][location]}
                                                                                                            onChange={(event) => this.handleChange(event, i, shippingDetail, key, location)}/>
                                                                                                    </div>
                                                                                                </fieldset>
                                                                                            </div>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        );
                                                                    })
                                                                }

                                                            </div>
                                                        </div>);
                                                })}

                                                {/*end price part*/}

                                                <div className="offset-xl-3 offset-sm-4">
                                                    <button type="submit" className="btn btn-primary"
                                                            onClick={this.handleSaveClick.bind(this)}>Save
                                                    </button>
                                                    <Link to="/new/shipping">
                                                        <button type="button" className="btn btn-light">Cancel</button>
                                                    </Link>
                                                </div>
                                            </AvForm>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer/>
            </Fragment>
        );
    }
}

export default CountryDetails
