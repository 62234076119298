import React, {Component, Fragment} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import {AvField, AvForm} from 'availity-reactstrap-validation';

import 'react-toastify/dist/ReactToastify.css';
import {getCountriesNew, saveCountries} from "../../../services/api";
import {toast, ToastContainer} from 'react-toastify';
import {Link} from "react-router-dom";


export class ShippingNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            countries: [],
        }
    }

    componentDidMount() {
        getCountriesNew()
            .then(data => {
                this.setState({
                    countries: data,
                })
            })
            .catch((err) => {
                toast.error("Can't load countries")
            });
    }

    addNewCountry = () => {
        console.log('add');
    };

    editCountry = (country) => {
        console.log('edit');
    };

    render() {
        const {countries} = this.state;

        return (
            <Fragment>
                <Breadcrumb title="Shipping Pricing New" parent="Physical"/>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Shipping</h5>
                                </div>
                                <div className="form-group row offset-xl-3 offset-sm-4"
                                     style={{marginTop: '30px'}}>
                                    <Link to={`/countries`}>
                                        <button type="submit" className="btn"
                                                onClick={this.addNewCountry}
                                                style={{
                                                    backgroundColor: '#8DC63F',
                                                    borderColor: '#8DC63F',
                                                    color: 'white'
                                                }}>
                                            Add new Country
                                        </button>
                                    </Link>
                                </div>
                                <div className="card-body">
                                    <div className="row product-adding">
                                        <div className="col-xl-7">
                                            <AvForm className="needs-validation add-product-form"
                                                    onValidSubmit={this.handleValidSubmit}
                                                    onInvalidSubmit={this.handleInvalidSubmit}>
                                                <div className="form" style={{paddingRight: '9%'}}>
                                                    {
                                                        countries.map((country, i) => {
                                                            return (
                                                                <div
                                                                    style={{border: '1px solid black', padding: '15px'}}
                                                                    key={i}>
                                                                    <div className="form-group row">
                                                                        <label className="col-xl-3 col-sm-4 mb-0"
                                                                               style={{paddingBottom: '13px'}}>Country
                                                                            name:</label>
                                                                        <div className="col-xl-8 col-sm-7">
                                                                            <AvField className="form-control mb-0"
                                                                                     name={"country" + i}
                                                                                     type="text" value={country.name}
                                                                                     editable={false} disabled
                                                                                     required={true}/>
                                                                        </div>
                                                                        <div className="valid-feedback">Looks good!
                                                                        </div>
                                                                    </div>
                                                                    {country.isActive ?
                                                                        <p style={{
                                                                            color: '#52b71f',
                                                                            fontSize: '16px',
                                                                            fontWeight: 'bold',
                                                                            borderRadius: '50%',
                                                                            border: '1px solid #52b71f',
                                                                            textAlign: 'center',
                                                                            width: '120px'
                                                                        }}>Active</p>
                                                                        :
                                                                        <p style={{
                                                                            color: 'red',
                                                                            fontSize: '16px',
                                                                            fontWeight: 'bold',
                                                                            borderRadius: '50%',
                                                                            border: '1px solid red',
                                                                            textAlign: 'center',
                                                                            width: '120px'
                                                                        }}>Not Active</p>
                                                                    }
                                                                    <div className="form-group row">
                                                                        <Link to={`/countries?countryId=${country.id}`}
                                                                              style={{marginLeft: '30%'}}>
                                                                            <button className="btn "
                                                                                    style={{
                                                                                        backgroundColor: 'rgb(255 249 0)',
                                                                                        color: 'black',

                                                                                    }}
                                                                                    onClick={() => this.editCountry(country)}>Edit
                                                                            </button>
                                                                        </Link>
                                                                    </div>

                                                                </div>
                                                            );
                                                        })
                                                    }

                                                </div>
                                            </AvForm>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer/>
            </Fragment>
        )
    }
}

export default ShippingNew
