import React, {Component, Fragment} from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export class Datatable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedValues: [],
      orders: this.props.orders
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.state.orders.length !== nextProps.orders.length) {
      this.setState({
        orders: nextProps.orders,
      });
    }
  }

  selectRow = (e, i) => {
    if (!e.target.checked) {
      this.setState({
        checkedValues: this.state.checkedValues.filter((item, j) => i !== item)
      });
    } else {
      this.state.checkedValues.push(i);
      this.setState({
        checkedValues: this.state.checkedValues
      })
    }
  }

  handleRemoveRow = () => {
    const selectedValues = this.state.checkedValues;
    const updatedData = this.state.orders.filter(function (el) {
      return selectedValues.indexOf(el.id) < 0;
    });
    this.setState({
      orders: updatedData
    });
    toast.success("Successfully Deleted !")
  };

  renderEditable = (cellInfo) => {
    return (
      <div
        style={{backgroundColor: "#fafafa"}}
        contentEditable = {false}
        suppressContentEditableWarning
        onBlur={e => {
          const data = [...this.state.orders];
          data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
          this.setState({orders: data});
        }}
        dangerouslySetInnerHTML={{
          __html: this.state.orders[cellInfo.index][cellInfo.column.id]
        }}
      />
    );
  }

  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  render() {
    const {pageSize, myClass, multiSelectOption, pagination} = this.props;
    const {orders} = this.state;

    const columns = [];
    for (var key in orders[0]) {

      let editable = this.renderEditable;
      if (key === "products") {
        editable = null;
      }
      if (key === "status") {
        editable = null;
      }
      if (key === "avtar") {
        editable = null;
      }
      if (key === "vendor") {
        editable = null;
      }
      if (key === "order_status") {
        editable = null;
      }

      columns.push(
        {
          Header: <b>{this.Capitalize(key.toString())}</b>,
          accessor: key,
          Cell: editable,
          style: {
            whiteSpace: 'pre'
          }
        });
    }


    return (
      <Fragment>
        <ReactTable
          data={orders}
          columns={columns}
          defaultPageSize={pageSize}
          className={myClass}
          showPagination={pagination}
          style={{overflow:'wrap'}}
        />
        <ToastContainer/>
      </Fragment>
    )
  }
}

export default Datatable
