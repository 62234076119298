import axios from 'axios';
import {getToken, userIsAuthorized} from "./utility";


export function request(data) {
  const defaultHeaders = {
    'X-Requested-With': 'XMLHttpRequest',
  };

  if (userIsAuthorized()) {
    defaultHeaders['jwt-auth-token'] = getToken();
  }

  const resultData = { ...data, withCredentials: true,  headers: { ...data.headers, ...defaultHeaders } };

  return axios(resultData);
}

const instance = axios.create({
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
});

export function requestWithoutJwt(requestData) {
  return instance(requestData).catch(err => {
    return Promise.reject(err);
  });
}
