import jwtDecode from 'jwt-decode';
import {config} from '../config';

export function getToken() {
  return localStorage.getItem(config.jwtAuthToken);
}

export function setToken(token) {
  localStorage.setItem(config.jwtAuthToken, token);
}

export function deleteToken() {
  localStorage.removeItem(config.jwtAuthToken);
}

export function validJWT(token) {
  try {
    const tokenData = jwtDecode(token);
    return Date.now() < tokenData.exp * 1000;
  } catch (e) {
    return false;
  }
}

export default function authenticate(token) {
  const isValidJwt = validJWT(token);
  if (!isValidJwt) {
    localStorage.setItem(config.jwtAuthToken, null);
  }
  return isValidJwt;
}

export function userIsAuthorized() {
  if (!getToken()) {
    return false;
  }
  return validJWT(getToken());
}
