import React, {Component, Fragment} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import {AvField, AvForm} from 'availity-reactstrap-validation';

import 'react-toastify/dist/ReactToastify.css';
import {getCountries, saveCountries} from "../../../services/api";
import {toast, ToastContainer} from 'react-toastify';


export class Shipping extends Component {

  constructor(props) {
    super(props);
    this.state = {
      countryPriceMap: [],
      countryPriceMapOld: [],
    }
  }

  componentDidMount() {
    getCountries()
      .then(data => {
        console.log(data)
        this.setState({
          countryPriceMap: data,
          countryPriceMapOld: [...data]
        })
      })
      .catch((err) => {
        toast.error("Can't load countries")
      });
  }

  handleCancelClick() {
    const {countryPriceMapOld} = this.state;
    console.log(countryPriceMapOld)
    this.setState({
      countryPriceMap: [...countryPriceMapOld]
    })
  }

  handleSaveClick() {
    const {countryPriceMap} = this.state;

    saveCountries(countryPriceMap)
      .then(data => {
        this.setState({
          countryPriceMap: data,
          countryPriceMapOld: [...data]
        });
        toast.success("Successfully Saved!");
      })
      .catch((err) => {
        toast.error("Can't Save now!")
      });
  }

  changeCountryName = (event, index) => {
    const countryPriceMap = this.state.countryPriceMap;
    countryPriceMap[index].name = event.target.value;
    this.setState(
      countryPriceMap
    );
  };
  changeCountryPrice = (event, index) => {
    const countryPriceMap = this.state.countryPriceMap;
    countryPriceMap[index].priceForKg = event.target.value;
    this.setState(
      countryPriceMap
    );
  };
  addNewCountry = () => {
    const countryPriceMap = this.state.countryPriceMap;
    const oneSize = {
      name: 'Country',
      priceForKg: 10.0
    };
    countryPriceMap.push(oneSize);
    this.setState(
      countryPriceMap
    );
  };
  deleteCountry = (index) => {
    const countryPriceMap = this.state.countryPriceMap;
    countryPriceMap.splice(index, 1);
    this.setState(
      countryPriceMap
    );
  };

  render() {
    const {countryPriceMap} = this.state;
    console.log(countryPriceMap)

    return (
      <Fragment>
        <Breadcrumb title="Shipping Pricing" parent="Physical"/>

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Shipping</h5>
                </div>
                <div className="card-body">
                  <div className="row product-adding">
                    <div className="col-xl-7">
                      <AvForm className="needs-validation add-product-form" onValidSubmit={this.handleValidSubmit}
                              onInvalidSubmit={this.handleInvalidSubmit}>
                        <div className="form" style={{paddingRight: '9%'}}>
                          {
                            countryPriceMap.map((countryPrice, i) => {
                              return (
                                <div style={{border: '1px solid black', padding: '15px'}} key={i}>
                                  <div className="form-group row">
                                    <label className="col-xl-3 col-sm-4 mb-0" style={{paddingBottom: '13px'}}>Country
                                      name:</label>
                                    <div className="col-xl-8 col-sm-7">
                                      <AvField className="form-control mb-0" name={"country" + i}
                                               type="text" value={countryPrice.name}
                                               required={true} onChange={(event) => this.changeCountryName(event, i)}/>
                                    </div>
                                    <div className="valid-feedback">Looks good!</div>
                                  </div>
                                  <div className="form-group row">
                                    <button className="btn "
                                            style={{backgroundColor: '#CB292A', color: 'white', marginLeft: '30%'}}
                                            onClick={() => this.deleteCountry(i)}>Delete
                                    </button>
                                  </div>

                                </div>
                              );
                            })
                          }
                          <div className="form-group row offset-xl-3 offset-sm-4" style={{marginTop: '30px'}}>
                            <button type="submit" className="btn" onClick={this.addNewCountry}
                                    style={{backgroundColor: '#8DC63F', borderColor: '#8DC63F', color: 'white'}}>
                              Add new Country
                            </button>
                          </div>
                        </div>
                        <div className="offset-xl-3 offset-sm-4" style={{marginTop: '50px'}}>
                          <button type="submit" className="btn btn-primary"
                                  onClick={this.handleSaveClick.bind(this)}>Save
                          </button>
                          <button type="button" className="btn btn-light"
                                  onClick={this.handleCancelClick.bind(this)}>Cancel
                          </button>
                        </div>
                      </AvForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer/>
      </Fragment>
    )
  }
}

export default Shipping
