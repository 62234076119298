import React, {Component, Fragment} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import {AvField, AvForm} from 'availity-reactstrap-validation';
import user from '../../../assets/images/dashboard/designer.jpg';

import Switch from "react-switch";

import {getCategories, getProductById, saveProduct, uploadFile} from '../../../services/api'

import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    CategoryMap,
    CategoryReverseMap,
    KidsSubCategoryReverseMap, Locations,
    MenSubCategoryReverseMap,
    SubCategoryMap,
    WomenSubCategoryReverseMap
} from '../../../config';
import {Link} from "react-router-dom";
import * as qs from "query-string";


export class Add_product extends Component {

    constructor(props) {
        super(props);
        this.state = {
            product: {},
            id: null,
            productName: '',
            description: '',
            details: '',
            price: 100000,
            salePrice: 100000,
            priceAMD: 100000,
            salePriceAMD: 100000,
            weightInGrams: 100,
            categories: [],
            sizeAndCountMap: [],
            selectedCategory: 'Men',
            selectedSubCategory: 't-shirts',
            isNew: true,
            isSale: false,
            isEnabled: true,
            isDeleted: false,
            file: '',
            imagesCount: 10,
            images: ['', '', '', '', '', '', '', '', '', '']
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {location: {search}} = nextProps;
        const queries = qs.parse(search, {ignoreQueryPrefix: true});
        const productId = queries.productId;
        const {imagesCount} = this.state;
        if (productId) {
            getProductById(productId)
                .then(product => {
                        if (product) {
                            const images = [];
                            const productImagesLength = product.pictures.length;
                            for (let i = 0; i < imagesCount; i++) {
                                images[i] = productImagesLength > i ? product.pictures[i] : '';
                            }
                            this.setState({
                                id: product.id,
                                images,
                                productName: product.name,
                                price: product.priceMap ? product.priceMap[Locations.OTHER] : 0,
                                priceAMD: product.priceMap ? product.priceMap[Locations.ARMENIA] : 0,
                                priceEUROPE: product.priceMap ? product.priceMap[Locations.EUROPE] : 0,
                                priceRUSSIA: product.priceMap ? product.priceMap[Locations.RUSSIA] : 0,
                                salePrice: product.saleMap ? product.saleMap[Locations.OTHER] : 0,
                                salePriceAMD: product.saleMap ? product.saleMap[Locations.ARMENIA] : 0,
                                salePriceEUROPE: product.saleMap ? product.saleMap[Locations.EUROPE] : 0,
                                salePriceRUSSIA: product.saleMap ? product.saleMap[Locations.RUSSIA] : 0,
                                pictures: product.pictures,
                                isNew: product.isNew,
                                isSale: product.isSale,
                                isEnabled: product.isEnabled,
                                isDeleted: product.isDeleted,
                                sizeAndCountMap: product.sizeAndCountList,
                                description: product.description,
                                details: product.details,
                                weightInGrams: product.weightInGrams
                            });
                        } else {
                            this.setState({
                                product: {},
                                id: null,
                                productName: '',
                                description: '',
                                details: '',
                                price: 100000,
                                salePrice: 100000,
                                priceAMD: 100000,
                                salePriceAMD: 100000,
                                weightInGrams: 100,
                                sizeAndCountMap: [],
                                selectedCategory: 'Men',
                                selectedSubCategory: 'T-Shirts',
                                isNew: true,
                                isSale: false,
                                isEnabled: true,
                                isDeleted: false,
                                images: ['', '', '', '', '', '', '', '', '', '']
                            });
                        }
                    }
                )
        } else {
            this.setState({
                product: {},
                id: null,
                productName: '',
                description: '',
                details: '',
                price: 100000,
                salePrice: 100000,
                priceAMD: 100000,
                salePriceAMD: 100000,
                weightInGrams: 100,
                sizeAndCountMap: [],
                selectedCategory: 'Men',
                selectedSubCategory: 'T-Shirts',
                isNew: true,
                isSale: false,
                isEnabled: true,
                isDeleted: false,
                images: ['', '', '', '', '', '', '', '', '', '']
            });
        }
    }

    componentDidMount() {
        const {location: {search}} = this.props;
        const queries = qs.parse(search, {ignoreQueryPrefix: true});
        const productId = queries.productId;
        const {imagesCount} = this.state;

        getCategories().then(data => {
            if (productId) {
                getProductById(productId)
                    .then(product => {
                            if (product) {
                                const images = [];
                                const productImagesLength = product.pictures.length;
                                for (let i = 0; i < imagesCount; i++) {
                                    images[i] = productImagesLength > i ? product.pictures[i] : '';
                                }
                                this.setState({
                                    id: product.id,
                                    images,
                                    productName: product.name,
                                    price: product.priceMap ? product.priceMap[Locations.OTHER] : 0,
                                    priceAMD: product.priceMap ? product.priceMap[Locations.ARMENIA] : 0,
                                    priceEUROPE: product.priceMap ? product.priceMap[Locations.EUROPE] : 0,
                                    priceRUSSIA: product.priceMap ? product.priceMap[Locations.RUSSIA] : 0,
                                    salePrice: product.saleMap ? product.saleMap[Locations.OTHER] : 0,
                                    salePriceAMD: product.saleMap ? product.saleMap[Locations.ARMENIA] : 0,
                                    salePriceEUROPE: product.saleMap ? product.saleMap[Locations.EUROPE] : 0,
                                    salePriceRUSSIA: product.saleMap ? product.saleMap[Locations.RUSSIA] : 0,
                                    pictures: product.pictures,
                                    isNew: product.isNew,
                                    isSale: product.isSale,
                                    isEnabled: product.isEnabled,
                                    isDeleted: product.isDeleted,
                                    selectedCategory: CategoryMap[product.category],
                                    selectedSubCategory: product.subCategory ? SubCategoryMap[product.subCategory] : '',
                                    sizeAndCountMap: product.sizeAndCountList,
                                    categories: data,
                                    description: product.description,
                                    details: product.details,
                                    weightInGrams: product.weightInGrams
                                });
                            } else {
                                this.setState({
                                    categories: data
                                });
                            }
                        }
                    )
            } else {
                this.setState({
                    categories: data
                });
            }

        });
    }

    handleSaveClick() {

        const priceMap = {
            "ARMENIA": this.state.priceAMD,
            "EUROPE": this.state.priceEUROPE,
            "RUSSIA": this.state.priceRUSSIA,
            "OTHER": this.state.price,
        };
        const saleMap = {
            "ARMENIA": this.state.salePriceAMD,
            "EUROPE": this.state.salePriceEUROPE,
            "RUSSIA": this.state.salePriceRUSSIA,
            "OTHER": this.state.salePrice,
        };
        const product = {
            id: this.state.id,
            name: this.state.productName,
            priceMap: priceMap,
            saleMap: saleMap,
            weightInGrams: this.state.weightInGrams,
            pictures: this.state.images,
            isNew: this.state.isNew,
            isSale: this.state.isSale,
            isEnabled: this.state.isEnabled,
            isDeleted: this.state.isDeleted,
            category: CategoryReverseMap[this.state.selectedCategory],
            subCategory: this.state.selectedSubCategory && this.state.selectedCategory === 'Men' ? MenSubCategoryReverseMap[this.state.selectedSubCategory]
                : this.state.selectedCategory === 'Kids' ? KidsSubCategoryReverseMap[this.state.selectedSubCategory] : WomenSubCategoryReverseMap[this.state.selectedSubCategory],
            sizeAndCountList: this.state.sizeAndCountMap,
            description: this.state.description,
            details: this.state.details
        };

        saveProduct(product)
            .then(data => {
                this.setState({
                    id: data.id
                });
                toast.success("Successfully Saved!");
            })
            .catch((err) => {
                toast.error("Can't Save now!")
            });
    }

    IncrementItem = (index) => {
        const sizeAndCountMap = this.state.sizeAndCountMap;
        sizeAndCountMap[index].count++;
        this.setState(
            sizeAndCountMap
        );
    };
    DecreaseItem = (index) => {
        const sizeAndCountMap = this.state.sizeAndCountMap;
        if (sizeAndCountMap[index].count !== 0) {
            sizeAndCountMap[index].count--;
            this.setState(
                sizeAndCountMap
            );
        }
    };

    changeField = (event, name) => {
        this.setState({
            [name]: event.target.value
        });
    };
    changeSizeName = (event, index) => {
        const sizeAndCountMap = this.state.sizeAndCountMap;
        sizeAndCountMap[index].size = event.target.value;
        this.setState(
            sizeAndCountMap
        );
    };
    handleChange = (event, index) => {
        const sizeAndCountMap = this.state.sizeAndCountMap;
        sizeAndCountMap[index].count = event.target.value;
        this.setState(
            sizeAndCountMap
        );
    };
    addNewSize = () => {
        const sizeAndCountMap = this.state.sizeAndCountMap;
        const oneSize = {
            size: 'size',
            count: 1
        };
        sizeAndCountMap.push(oneSize);
        this.setState(
            sizeAndCountMap
        );
    };
    deleteSize = (index) => {
        const sizeAndCountMap = this.state.sizeAndCountMap;
        sizeAndCountMap.splice(index, 1);
        this.setState(
            sizeAndCountMap
        );
    };
    changeIsNew = () => {
        this.setState({
            isNew: !this.state.isNew
        });
    };
    changeIsSale = () => {
        this.setState({
            isSale: !this.state.isSale
        });
    };
    changeIsEnabled = () => {
        this.setState({
            isEnabled: !this.state.isEnabled
        });
    };
    changeIsDeleted = () => {
        this.setState({
            isDeleted: !this.state.isDeleted
        });
    };

    deleteImage(index) {
        const {images} = this.state;
        images[index] = null;
        this.setState({
            images
        });
    }
    shiftImage(count, index) {
        const {images} = this.state;
        this.array_move(images, index, index + count);
        this.setState({
            images
        })
    }

    array_move(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
    };

    _handleSubmit(e) {
        e.preventDefault();
    }

    _handleImgChange(e, i) {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        const {images} = this.state;

        reader.onloadend = () => {
            const data = new FormData();
            data.append('file', file);
            uploadFile(data)
                .then(data => {
                    images[i] = data;
                    this.setState({
                        file: file,
                        images,
                    });
                });


        };
        if (file) {
            reader.readAsDataURL(file);
        }
    }

    render() {
        const {categories, sizeAndCountMap} = this.state;
        let subCategories;
        categories.map(category => {
                if (CategoryMap[category.category] === this.state.selectedCategory) {
                    subCategories = category.subCategories;
                }
            }
        );
        return (
            <Fragment>
                <Breadcrumb title="Add / Edit Product" parent="Physical"/>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Add / Edit Product</h5>
                                </div>
                                <div className="card-body">
                                    <div className="row product-adding">
                                        <div className="col-xl-5">
                                            <div className="add-product">
                                                <div className="row">
                                                    <div className="col-xl-3 xl-50 col-sm-6 col-3">
                                                        <ul className="file-upload-product">
                                                            {
                                                                this.state.images.map((res, i) => {
                                                                    return (
                                                                        <li key={i}>
                                                                            <b>{i + 1}. </b>
                                                                            <div className="box-input-file">
                                                                                <input className="upload" type="file"
                                                                                       onChange={(e) => this._handleImgChange(e, i)}/>
                                                                                <img
                                                                                    src={res && res !== '' ? res : user}
                                                                                    style={{
                                                                                        width: '450px',
                                                                                        height: '450px'
                                                                                    }}/>
                                                                                <a id="result1"
                                                                                   onClick={(e) => this._handleSubmit(e.target.id)}/>
                                                                            </div>
                                                                            <div style={{fontSize: "3rem"}}>
                                                                                <i className="fa fa-angle-up pull-up"
                                                                                   onClick={() => this.shiftImage(-1, i)}/>
                                                                                <i className="fa fa-angle-down pull-down"
                                                                                   onClick={() => this.shiftImage(1, i)}/>
                                                                                <i className="fa fa-trash"
                                                                                   onClick={() => this.deleteImage(i)}/>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-7">
                                            <AvForm className="needs-validation add-product-form"
                                                    onValidSubmit={this.handleValidSubmit}
                                                    onInvalidSubmit={this.handleInvalidSubmit}>
                                                <div className="form form-label-center">
                                                    <div className="form-group mb-3 row">
                                                        <label className="col-xl-3 col-sm-4 mb-0">Product Name:</label>
                                                        <div className="col-xl-8 col-sm-7">
                                                            <AvField className="form-control" name="product_name"
                                                                     type="text" required value={this.state.productName}
                                                                     onChange={(event) => this.changeField(event, 'productName')}/>
                                                        </div>
                                                        <div className="valid-feedback">Looks good!</div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-sm-4 mb-0">Select Type:</label>
                                                        <div className="col-xl-8 col-sm-7">
                                                            <select className="form-control digits"
                                                                    value={this.state.selectedCategory}
                                                                    onChange={(event) => this.changeCategory(event)}>
                                                                {
                                                                    categories.map((category, i) => {
                                                                        return (
                                                                            <option key={i}>
                                                                                {CategoryMap[category.category]}
                                                                            </option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-sm-4 mb-0">Select
                                                            SubType:</label>
                                                        <div className="col-xl-8 col-sm-7">
                                                            <select className="form-control digits"
                                                                    value={this.state.selectedSubCategory}
                                                                    onChange={(event) => this.changeField(event, 'selectedSubCategory')}>
                                                                {
                                                                    subCategories && subCategories.map((subCategory, i) => {
                                                                        return (
                                                                            <option key={i}>
                                                                                {SubCategoryMap[subCategory]}
                                                                            </option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div style={{border: '1px solid black', padding: '2%'}}>
                                                        <div className="form-group mb-3 row">
                                                            <label className="col-xl-3 col-sm-4 mb-0">Price USD:</label>
                                                            <div className="col-xl-8 col-sm-7">
                                                                <AvField className="form-control mb-0" name="price"
                                                                         type="number" required value={this.state.price}
                                                                         onChange={(event) => this.changeField(event, 'price')}/>
                                                            </div>
                                                            <div className="valid-feedback">Looks good!</div>
                                                        </div>
                                                        <div className="form-group mb-3 row">
                                                            <label className="col-xl-3 col-sm-4 mb-0">Sale Price
                                                                USD:</label>
                                                            <div className="col-xl-8 col-sm-7">
                                                                <AvField className="form-control mb-0" name="price"
                                                                         type="number" value={this.state.salePrice}
                                                                         onChange={(event) => this.changeField(event, 'salePrice')}/>
                                                            </div>
                                                            <div className="valid-feedback">Looks good!</div>
                                                        </div>
                                                    </div>
                                                    <div style={{border: '1px solid black', padding: '2%'}}>
                                                        <div className="form-group mb-3 row">
                                                            <label className="col-xl-3 col-sm-4 mb-0">Price RUB:</label>
                                                            <div className="col-xl-8 col-sm-7">
                                                                <AvField className="form-control mb-0" name="price"
                                                                         type="number" required
                                                                         value={this.state.priceRUSSIA}
                                                                         onChange={(event) => this.changeField(event, 'priceRUSSIA')}/>
                                                            </div>
                                                            <div className="valid-feedback">Looks good!</div>
                                                        </div>
                                                        <div className="form-group mb-3 row">
                                                            <label className="col-xl-3 col-sm-4 mb-0">Sale Price RUB
                                                                :</label>
                                                            <div className="col-xl-8 col-sm-7">
                                                                <AvField className="form-control mb-0" name="price"
                                                                         type="number"
                                                                         value={this.state.salePriceRUSSIA}
                                                                         onChange={(event) => this.changeField(event, 'salePriceRUSSIA')}/>
                                                            </div>
                                                            <div className="valid-feedback">Looks good!</div>
                                                        </div>
                                                    </div>
                                                    <div style={{border: '1px solid black', padding: '2%'}}>
                                                        <div className="form-group mb-3 row">
                                                            <label className="col-xl-3 col-sm-4 mb-0">Price
                                                                EURO:</label>
                                                            <div className="col-xl-8 col-sm-7">
                                                                <AvField className="form-control mb-0" name="price"
                                                                         type="number" required
                                                                         value={this.state.priceEUROPE}
                                                                         onChange={(event) => this.changeField(event, 'priceEUROPE')}/>
                                                            </div>
                                                            <div className="valid-feedback">Looks good!</div>
                                                        </div>
                                                        <div className="form-group mb-3 row">
                                                            <label className="col-xl-3 col-sm-4 mb-0">Sale Price EURO
                                                                :</label>
                                                            <div className="col-xl-8 col-sm-7">
                                                                <AvField className="form-control mb-0" name="price"
                                                                         type="number"
                                                                         value={this.state.salePriceEUROPE}
                                                                         onChange={(event) => this.changeField(event, 'salePriceEUROPE')}/>
                                                            </div>
                                                            <div className="valid-feedback">Looks good!</div>
                                                        </div>
                                                    </div>
                                                    <div style={{border: '1px solid black', padding: '2%'}}>
                                                        <div className="form-group mb-3 row">
                                                            <label className="col-xl-3 col-sm-4 mb-0">Price AMD:</label>
                                                            <div className="col-xl-8 col-sm-7">
                                                                <AvField className="form-control mb-0" name="priceAMD"
                                                                         type="number" required
                                                                         value={this.state.priceAMD}
                                                                         onChange={(event) => this.changeField(event, 'priceAMD')}/>
                                                            </div>
                                                            <div className="valid-feedback">Looks good!</div>
                                                        </div>
                                                        <div className="form-group mb-3 row">
                                                            <label className="col-xl-3 col-sm-4 mb-0">Sale Price
                                                                AMD:</label>
                                                            <div className="col-xl-8 col-sm-7">
                                                                <AvField className="form-control mb-0" name="priceAMD"
                                                                         type="number" value={this.state.salePriceAMD}
                                                                         onChange={(event) => this.changeField(event, 'salePriceAMD')}/>
                                                            </div>
                                                            <div className="valid-feedback">Looks good!</div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group mb-3 row">
                                                        <label className="col-xl-3 col-sm-4 mb-0">Weight in
                                                            Grams:</label>
                                                        <div className="col-xl-8 col-sm-7">
                                                            <AvField className="form-control mb-0" name="weight"
                                                                     type="number" value={this.state.weightInGrams}
                                                                     onChange={(event) => this.changeField(event, 'weightInGrams')}/>
                                                        </div>
                                                        <div className="valid-feedback">Looks good!</div>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-3 row">
                                                    <label className="col-xl-3 col-sm-4 mb-0">Enabled:</label>
                                                    <div className="col-xl-8 col-sm-7">
                                                        <Switch onChange={this.changeIsEnabled}
                                                                checked={this.state.isEnabled}/>
                                                    </div>
                                                    <div className="valid-feedback">Looks good!</div>
                                                </div>
                                                <div className="form-group mb-3 row">
                                                    <label className="col-xl-3 col-sm-4 mb-0">Deleted:</label>
                                                    <div className="col-xl-8 col-sm-7">
                                                        <Switch onChange={this.changeIsDeleted}
                                                                checked={this.state.isDeleted}/>
                                                    </div>
                                                    <div className="valid-feedback">Looks good!</div>
                                                </div>
                                                <div className="form-group mb-3 row">
                                                    <label className="col-xl-3 col-sm-4 mb-0">New:</label>
                                                    <div className="col-xl-8 col-sm-7">
                                                        <Switch onChange={this.changeIsNew} checked={this.state.isNew}/>
                                                    </div>
                                                    <div className="valid-feedback">Looks good!</div>
                                                </div>
                                                <div className="form-group mb-3 row">
                                                    <label className="col-xl-3 col-sm-4 mb-0">Sale:</label>
                                                    <div className="col-xl-8 col-sm-7">
                                                        <Switch onChange={this.changeIsSale}
                                                                checked={this.state.isSale}/>
                                                    </div>
                                                    <div className="valid-feedback">Looks good!</div>
                                                </div>

                                                <div className="form" style={{paddingRight: '9%'}}>
                                                    {
                                                        sizeAndCountMap.map((sizeAndCount, i) => {
                                                            return (
                                                                <div key={i} style={{
                                                                    border: '1px solid black',
                                                                    padding: '15px'
                                                                }}>
                                                                    <div className="form-group row">
                                                                        <label
                                                                            className="col-xl-3 col-sm-4 mb-0">Size:</label>
                                                                        <div className="col-xl-8 col-sm-7">
                                                                            <AvField className="form-control mb-0"
                                                                                     name="size"
                                                                                     type="text"
                                                                                     value={sizeAndCount.size}
                                                                                     required
                                                                                     onChange={(event) => this.changeSizeName(event, i)}/>
                                                                        </div>
                                                                        <div className="valid-feedback">Looks good!
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <label
                                                                            className="col-xl-3 col-sm-4 mb-0">Count:</label>
                                                                        <fieldset className="qty-box ml-0">
                                                                            <div
                                                                                className="input-group bootstrap-touchspin">
                                                                                <div className="input-group-prepend">
                                                                                    <button
                                                                                        className="btn btn-primary btn-square bootstrap-touchspin-down"
                                                                                        type="button"
                                                                                        onClick={() => this.DecreaseItem(i)}>
                                                                                        <i className="fa fa-minus"/>
                                                                                    </button>
                                                                                </div>
                                                                                <div className="input-group-prepend">
                                                                                    <span
                                                                                        className="input-group-text bootstrap-touchspin-prefix"/>
                                                                                </div>
                                                                                <input
                                                                                    className="touchspin form-control"
                                                                                    type="text"
                                                                                    value={sizeAndCount.count}
                                                                                    onChange={(event) => this.handleChange(event, i)}/>
                                                                                <div className="input-group-append">
                                                                                    <span
                                                                                        className="input-group-text bootstrap-touchspin-postfix"/>
                                                                                </div>
                                                                                <div
                                                                                    className="input-group-append ml-0">
                                                                                    <button
                                                                                        className="btn btn-primary btn-square bootstrap-touchspin-up"
                                                                                        type="button"
                                                                                        onClick={() => this.IncrementItem(i)}>
                                                                                        <i className="fa fa-plus"/>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </fieldset>
                                                                        <button className="btn" type="button"
                                                                                onClick={() => this.deleteSize(i)}
                                                                                style={{
                                                                                    marginLeft: '20%',
                                                                                    backgroundColor: 'transparent'
                                                                                }}>
                                                                            <i className="fa fa-trash" style={{
                                                                                width: 55,
                                                                                fontSize: 35,
                                                                                color: '#e4566e'
                                                                            }}
                                                                            />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                    <div className="form-group row offset-xl-3 offset-sm-4"
                                                         style={{marginTop: '10px'}}>
                                                        <button type="submit" className="btn" onClick={this.addNewSize}
                                                                style={{
                                                                    backgroundColor: '#8DC63F',
                                                                    borderColor: '#8DC63F',
                                                                    color: 'white'
                                                                }}>
                                                            Add new size
                                                        </button>
                                                    </div>
                                                    <div className="form-group mb-3 row">
                                                        <label className="col-xl-3 col-sm-4 mb-0">Add
                                                            Description:</label>
                                                        <div className="col-xl-8 col-sm-7">
                                                            <AvField className="form-control" name="description"
                                                                     type="textarea" required
                                                                     value={this.state.description}
                                                                     onChange={(event) => this.changeField(event, 'description')}/>
                                                        </div>
                                                        <div className="valid-feedback">Looks good!</div>
                                                    </div>
                                                    <div className="form-group mb-3 row">
                                                        <label className="col-xl-3 col-sm-4 mb-0">Add Details:</label>
                                                        <div className="col-xl-8 col-sm-7">
                                                            <AvField className="form-control" name="details"
                                                                     type="textarea" required value={this.state.details}
                                                                     onChange={(event) => this.changeField(event, 'details')}/>
                                                        </div>
                                                        <div className="valid-feedback">Looks good!</div>
                                                    </div>
                                                </div>
                                                <div className="offset-xl-3 offset-sm-4">
                                                    <button type="submit" className="btn btn-primary"
                                                            onClick={this.handleSaveClick.bind(this)}>Save
                                                    </button>
                                                    <Link to="/products/product-list">
                                                        <button type="button" className="btn btn-light">Cancel</button>
                                                    </Link>
                                                </div>
                                            </AvForm>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer/>
            </Fragment>
        )
    }

    changeCategory(event) {
        const {categories} = this.state;
        let subCategory = '';
        categories.map(category => {
            if (CategoryMap[category.category] === event.target.value) {
                subCategory = category.subCategories && category.subCategories.length !== 0 ? category.subCategories[0] : '';
            }
        });
        this.setState({
            selectedCategory: event.target.value,
            selectedSubCategory: subCategory,
        })
    }
}

export default Add_product
