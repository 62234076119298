import {Anchor, Box, DollarSign, Home, Settings} from 'react-feather';

export const MENUITEMS = [
  {
    path: '/dashboard', title: 'Dashboard', icon: Home, type: 'link', badgeType: 'primary', active: false
  },
  {
    title: 'Products', icon: Box, type: 'sub', active: false, children: [

      // { path: '/products/physical/category', title: 'Category', type: 'link' },
      // { path: '/products/physical/sub-category', title: 'Sub Category', type: 'link' },
      {path: '/products/product-list', title: 'Product List', type: 'link'},
      {path: '/products/add-product', title: 'Add Product', type: 'link'},
      // {path: '/shipping', title: 'Shipping Pricing', type: 'link'},
      /*{
          title: 'digital', type: 'sub', active: false, children: [
              { path: '/products/digital/digital-category', title: 'Category', type: 'link' },
              { path: '/products/digital/digital-sub-category', title: 'Sub Category', type: 'link' },
              { path: '/products/digital/digital-product-list', title: 'Product List', type: 'link' },
              { path: '/products/digital/digital-add-product', title: 'Add Product', type: 'link' },
          ]
      },*/
    ]
  },
  {
    path: '/new/shipping', title: 'Shipping', icon: Anchor, type: 'link', badgeType: 'primary', active: false
  },
  {
    title: 'Sales', icon: DollarSign, type: 'sub', active: false, children: [
      {path: '/sales/orders', title: 'Orders', type: 'link'},
    ]
  },
  {
    title: 'Settings', icon: Settings, type: 'sub', children: [
      {path: '/settings/profile', title: 'Profile', type: 'link'},
    ]
  },
];
