import React, { Component,Fragment } from 'react'
import {Link} from 'react-router-dom'
//images import
import man from '../../../assets/images/dashboard/man.png'
import {deleteToken} from "../../../utils/utility";
export class User_menu extends Component {

    logOutUser = () => {
        deleteToken();
        window.location.href = `${process.env.PUBLIC_URL}/login`;
    };

    render() {
        return (
            <Fragment>
                    <li className="onhover-dropdown">
                        <div className="media align-items-center">
                            <img className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded" src={man} alt="header-user" />
                        </div>
                        <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover" >
                            <li><Link to={`${process.env.PUBLIC_URL}/settings/profile`} ><i data-feather="user"/>Edit Profile</Link></li>
                            <li><Link onClick={this.logOutUser}><i data-feather="log-out"/>Logout</Link></li>
                        </ul>
                    </li>
            </Fragment>
        )
    }
}

export default User_menu
