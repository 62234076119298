import React, {Component} from 'react';


export class Image extends Component {

  render() {
    return (
      <div>
          {this.props.data.map((res, i) => {
            return (
              <div style={{textAlign: 'left'}}>
                <p>
                  {i + 1 }) {res.name } | Size: {res.size} | Count: {res.qty} ->
                <img src={res.pictureUrl} alt="" key={i} className="img-fluid img-30 mr-2 blur-up lazyloaded"/>
                </p>
              </div>
            )
          })}
      </div>
    )
  }
}

export default Image
